// import axios from "axios";
// import { baseUrl } from "./baseUrl";

// export const get = async (route) => {
//   try {
//     let token = localStorage.getItem("token");
//     let response = await axios.get(baseUrl + route, {
//       headers: {
//         Authorization: token,
//       },
//     });
//     return response;
//   } catch (err) {
//     console.error(err.message);
//   }
// };

import axios from "axios";
import { baseUrl, analyticsUrl, analyticsUrl_2 } from "./baseUrl";

export const get = async (route) => {
  let token = localStorage.getItem("token");
  return await axios.get(baseUrl + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const get_analytics = (route) => {
  let token = localStorage.getItem("token");
  return fetch(analyticsUrl + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export function getAnalytics(string, startDate, endDate, interval) {
  return axios.get(
    `${analyticsUrl_2}/analytics/${string}/start=${startDate}/end=${endDate}/interval=${interval}`,
    {
      responseType: "blob",
      headers: {
        "Content-Type": "application/vnd.ms-excel",
        "Content-Disposition": 'attachment; filename="example.xlsx"',
      },
    }
  );
}

export const post = async (route, body) => {
  let token = localStorage.getItem("token");
  return await axios.post(baseUrl + route, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const remove = async (route) => {
  let token = localStorage.getItem("token");
  return await axios.delete(baseUrl + route, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
