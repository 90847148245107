import React from "react";
import axios from "axios";
import { Routes, Route, Link, useSearchParams } from "react-router-dom";
import { get, post, remove } from "../services/service";

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const sanitize = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("click", sanitize);

    return () => {
      document.removeEventListener("click", sanitize);
    };
  }, [ref]);

  return ref;
};

function Searchbar(props) {
  const [text, setText] = React.useState("");
  const [foundQuotes, setFoundQuotes] = React.useState({
    symbol: [],
    shortname: [],
  });
  const handleClickOutside = () => {
    setText("");
  };

  const ref = useOutsideClick(handleClickOutside);

  function onlySpaces(str) {
    return /^\s*$/.test(str);
  }

  async function findStocks(input) {
    setText(input);
    if (!onlySpaces(input)) {
      try {
        const response = await get(`/search/${input}`);
        setFoundQuotes(response.data.quotes);
        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }

  const sanitize = (ticker) => {
    if (!onlySpaces(ticker)) {
      // const upper = ticker.toUpperCase();
      props.input(ticker).then(function (result) {
        if (result) {
          setText("");
          console.log("verified");
        } else {
          console.log("not verified");
        }
      });
    }
  };

  return (
    <div className="searchbar">
      <div className="search-container">
        <div className="search-drop-container" ref={ref}>
          <input
            className="search"
            type="search"
            placeholder={props.placeholder}
            value={text}
            onChange={(e) => findStocks(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" ? sanitize(e.target.value) : null
            }
          ></input>
          {text && (
            <div className="dropdown">
              {foundQuotes.length > 0 &&
                foundQuotes.map((index) => {
                  return (
                    <div
                      className="dropdown-row"
                      key={index.symbol}
                      onClick={() => sanitize(index.symbol)}
                    >
                      <div style={{ fontWeight: "bold" }}>{index.symbol}</div>
                      <div>{index.shortname}</div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {props.placeholderStats.regularMarketChange ? (
        <div className="quick-price">
          <p
            className="main-quick-price"
            style={
              props.placeholderStats.regularMarketChange > 0
                ? { color: "#068B03" }
                : { color: "#F42727" }
            }
          >
            {props.placeholderStats.regularMarketPrice.toFixed(2)}
          </p>
          <p
            className="secondary-price"
            style={
              props.placeholderStats.regularMarketChange > 0
                ? { color: "#068B03" }
                : { color: "#F42727" }
            }
          >
            {props.placeholderStats.regularMarketChange.toFixed(2)}
          </p>
          <p
            className="secondary-price"
            style={
              props.placeholderStats.regularMarketChange > 0
                ? { color: "#068B03" }
                : { color: "#F42727" }
            }
          >
            ({props.placeholderStats.regularMarketChangePercent.toFixed(2)}%)
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Searchbar;
