import React from "react";
import axios from "axios";
import watch from "../assets/watch.png";
import stock from "../assets/stock.png";
import analytics from "../assets/analytics.png";
import trade from "../assets/trade.png";
import contact from "../assets/contact.png";
import defaultImage from "../assets/default.png";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { get, post, remove } from "../services/service";
import Contact from "./Contact";

function Sidebar(props) {
  // const [click, setClick] = React.useState(props.tab);
  // console.log(props.tab);

  const navigate = useNavigate();

  // navigate(`/${click}`);

  return (
    <nav className="sidebar">
      <div className="sidebar-button-group">
        <img
          // onClick={(e) => setClick(e.target.id)}
          onClick={(e) => navigate(`/dashboard/${e.target.id}`)}
          src={stock}
          height="42px"
          width="42px"
          alt="portfolio"
          className={
            props.tab === "portfolio"
              ? "sidebar-button-clicked"
              : "sidebar-button"
          }
          id="portfolio"
        />
        <img
          // onClick={(e) => setClick(e.target.id)}
          onClick={(e) => navigate(`/dashboard/${e.target.id}`)}
          src={watch}
          height="42px"
          width="42px"
          alt="watchlist"
          className={
            props.tab === "watchlist"
              ? "sidebar-button-clicked"
              : "sidebar-button"
          }
          id="watchlist"
        />
        <img
          // onClick={(e) => setClick(e.target.id)}
          onClick={(e) => navigate(`/dashboard/${e.target.id}`)}
          src={analytics}
          height="42px"
          width="42px"
          alt="analytics"
          className={
            props.tab === "analytics"
              ? "sidebar-button-clicked"
              : "sidebar-button"
          }
          id="analytics"
        />
        <img
          // onClick={(e) => setClick(e.target.id)}
          onClick={(e) => navigate(`/dashboard/${e.target.id}`)}
          src={trade}
          height="42px"
          width="42px"
          alt="trade"
          className={
            props.tab === "trade" ? "sidebar-button-clicked" : "sidebar-button"
          }
          id="trade"
        />
      </div>
      <img
        // onClick={(e) => setClick(e.target.id)}
        onClick={(e) => navigate(`/dashboard/${e.target.id}`)}
        src={contact}
        height="42px"
        width="42px"
        alt="contact"
        className={
          props.tab === "contact" ? "sidebar-button-clicked" : "sidebar-button"
        }
        id="contact"
      />
    </nav>
  );
}

export default Sidebar;
