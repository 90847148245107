import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import StockChart from "./components/StockChart";
import Watchlist from "./components/Watchlist";
import Summary from "./components/Summary";
import Analytics from "./components/Analytics";
import Trade from "./components/Trade";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import { get } from "./services/service";
import ViewProfile from "./components/ViewProfile";
import MyProfile from "./components/MyProfile";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import NotFound from "./components/NotFound";
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard";

function App() {
  // const [input, setInput] = React.useState("");
  const [form, setForm] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [close, setClose] = React.useState(true);
  // const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  // const search = (text) => {
  //   setInput(text);
  // };

  const loginCheck = async () => {
    try {
      let response = await get("/users/login-test");
      // console.log(response.data);
      // navigate("/dashboard/portfolio");
      // setLoading(false);
    } catch (err) {
      if (err.response.status === 440 || err.response.status === 400) {
        // console.log(err.response.status);
        localStorage.clear();
        // setLoading(false);
      }
    }
  };

  React.useEffect(function () {
    loginCheck();
  }, []);

  const click = (data) => {
    setOpen(data);
  };

  const clack = (data) => {
    setOpen(data);
  };

  return (
    <div className="App">
      {/* <Navbar search={search} openlogin={click} /> */}
      <Navbar openlogin={click} />

      <Routes>
        <Route
          path="/"
          element={<Home openlogin={open} closelogin={clack} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route
          path="/dashboard/:tab"
          element={<Dashboard />}
        /> */}
        {["/dashboard", "/dashboard/:tab"].map((path) => (
          <Route path={path} key={path} element={<Dashboard />} />
        ))}
        {/* <Route path="/stockchart/:input" element={<StockChart />} /> */}
        {/* <Route path="/analytics" element={<Analytics />} /> */}
        {/* <Route path="/paper-trading" element={<PaperTrading />} /> */}
        {/* <Route path="/view-profile/:userId" element={<ViewProfile />} /> */}
        <Route path="/my-profile" element={<MyProfile />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        {/* <Route path="/footer" element={<Footer />} /> */}
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
