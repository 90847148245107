import React from "react";
import { post } from "../services/service";
import { useParams, useNavigate, Link } from "react-router-dom";
// import bullbear from "../assets/bullbear.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { areDayPropsEqual } from "@mui/x-date-pickers/internals";

const Login = (props) => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [click, setClick] = React.useState(false);
  const [userStatus, setUserStatus] = React.useState("");
  const [passStatus, setPassStatus] = React.useState("");

  const navigate = useNavigate();

  const create = async (e) => {
    e.preventDefault();
    if (username === "mike" || "admin") {
      if (username.includes("@")) {
        try {
          if (!password) {
            localStorage.clear();
            setPassStatus("Please include password");
          } else {
            let response = await post("/users/login", {
              email: username,
              password: password,
            });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem(
              "profilePicture",
              response.data.profilePicture
            );
            if (response.data.message) {
              localStorage.clear();
              setStatus(response.data.message);
            } else {
              navigate("/dashboard/portfolio");
            }
          }
        } catch (err) {
          setStatus("Something went wrong");
        }
      } else {
        try {
          if (!username || !password) {
            if (!username) {
              localStorage.clear();
              setUserStatus("Please include username or email");
            }
            if (!password) {
              localStorage.clear();
              setPassStatus("Please include password");
            }
          } else {
            let response = await post("/users/login", {
              username: username,
              password: password,
            });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("username", response.data.username);
            localStorage.setItem("email", response.data.email);
            localStorage.setItem(
              "profilePicture",
              response.data.profilePicture
            );
            if (response.data.message) {
              localStorage.clear();
              setStatus(true);
              console.log(response.data.message);
            } else {
              navigate("/dashboard/portfolio");
            }
          }
        } catch (err) {
          console.log("Something went wrong");
        }
      }
    } else {
      console.log("permission denied");
    }
  };

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanUser = (value) => {
    setUserStatus("");
    setStatus(false);
    setUsername(value);
  };

  const cleanPass = (value) => {
    setPassStatus("");
    setStatus(false);
    setPassword(value);
  };

  return (
    <div className="login" ref={wrapperRef}>
      <h4>Log In</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "16px", width: "327px" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <TextField
          error={userStatus || status ? true : false}
          id="username-login"
          type="string"
          label="Username or Email"
          variant="standard"
          value={username}
          onChange={(e) => cleanUser(e.target.value)}
        />
        <TextField
          error={passStatus || status ? true : false}
          id="password-login"
          type="password"
          label="Password"
          variant="standard"
          value={password}
          onChange={(e) => cleanPass(e.target.value)}
        />
        <p>
          Don't have an account?
          <span
            onClick={() => {
              props.form("signup");
            }}
          >
            {" "}
            Sign Up
          </span>
        </p>

        <button type="submit" className="login-button">
          Log In
        </button>
      </Box>
      {/* <p style={{ color: "red" }}>{status}</p> */}
    </div>
  );
};

export default Login;
