import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { get, post, remove } from "../services/service";

function News(props) {
  // const [news, setNews] = React.useState();
  // const [hoursAgo, setHoursAgo] = React.useState();
  const [results, setResults] = React.useState([]);

  async function fetchNews() {
    if (props.ticker) {
      try {
        const response = await get(`/search/${props.ticker}`);
        // console.log(response);

        const quotes = response.data.news
          .map((quote) => {
            const thumbnail = quote.thumbnail;
            if (!thumbnail) {
              return null; // skip articles without a thumbnail
            }
            return {
              ...quote,
              hoursAgo: Math.floor(
                (new Date() - new Date(quote.providerPublishTime) * 1000) /
                  (1000 * 60 * 60)
              ),
              thumbnailUrl: thumbnail.contentUrl,
            };
          })
          .filter((quote) => quote !== null); // remove null values

        // console.log(quotes);
        setResults(quotes);

        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }
  // console.log(date);

  React.useEffect(() => {
    fetchNews();
  }, [props.ticker]);
  if (results) {
    return (
      <div className="news">
        <div className="news-header">News</div>
        {/* <div className="news-content"> */}
        {results.map((item, index) => (
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="news-content"
            style={
              index !== results.length - 1
                ? {
                    borderBottom: "solid 2.55px #CED5DE",
                    paddingBottom: "28px",
                  }
                : { borderBottom: "", paddingBottom: "0px" }
            }
            key={index}
          >
            <img
              src={item.thumbnail.resolutions[0].url}
              alt="Girl in a jacket"
              style={{ width: "217px", height: "112px" }}
            />
            <div className="news-text">
              <span className="news-title">
                {item.publisher} - <span>{item.title}</span>
              </span>

              <span className="hours">{item.hoursAgo} hours ago</span>
            </div>
          </a>
        ))}
        {/* <img
            src={news.thumbnail.resolutions[0].url}
            alt="Girl in a jacket"
            style={{ width: "217px", height: "112px" }}
          />
          <div className="news-text">
            <span className="news-title">
              {news.publisher} - <span>{news.title}</span>
            </span>

            <span className="hours">{hoursAgo} hours ago</span>
          </div> */}
        {/* </div> */}
      </div>
    );
  }
}

export default News;
