import React from "react";
import { get, post, remove } from "../services/service";
import { useParams, useNavigate } from "react-router-dom";
import defaultImage from "../assets/default.png";

const MyProfile = () => {
  const [user, setUser] = React.useState(null);
  const [status, setStatus] = React.useState("");
  const [posts, setPosts] = React.useState([]);
  const [imgUrl, setImgUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const profilePicture = localStorage.getItem("profilePicture");

  React.useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    fetchUser();
    fetchPosts();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await get(`/users/my-profile`);
      setUser(response.data);
    } catch (err) {
      setStatus(err.message);
    }
  };

  const fetchPosts = async () => {
    try {
      const response = await get(`/posts/my-posts`);
      setPosts(response.data);
    } catch (err) {
      setStatus(err.message);
    }
  };

  const deletePost = async (id) => {
    try {
      const response = await remove(`/posts/delete-post/${id}`);
      fetchPosts();
    } catch (err) {
      setStatus(err.message);
    }
  };

  const deleteUser = async () => {
    try {
      const response = await remove(`/users/delete-user/${user._id}`);
      localStorage.clear();
      navigate("/");
    } catch (err) {
      setStatus(err.message);
    }
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleFileUpload = async (e) => {
    setLoading(true);
    const uploadData = new FormData();
    uploadData.append("imageUrl", e.target.files[0]);
    let response = await post("/users/add-picture", uploadData);
    setImgUrl(response.data.path);
    setLoading(false);
    let response2 = await post("/users/update", {
      profilePic: response.data.path,
    });
    localStorage.setItem("profilePicture", response2.data.profilePic);
    navigate("/my-profile");
  };

  return user ? (
    <div className="my-profile">
      <div className="wrapper">
        <img
          src={profilePicture || defaultImage}
          alt="profile"
          height="200px"
          width="200px"
          className="roundImage"
          onClick={(e) => this.myInput.click()}
        />
        <input type="file" onChange={handleFileUpload} className="files" />
      </div>

      <span>
        <h2>My Profile</h2>
      </span>
      <h2>@{user.username}</h2>
      <button onClick={logOut}>Log Out</button>
      <button onClick={deleteUser}>Delete Account</button>
      <h3>Posts</h3>
      {posts.map(function (post) {
        return (
          <div key={post._id}>
            <hr></hr>
            <p>{post.ticker}</p>
            <p>{post.content}</p>
            <p>{post.date}</p>
            <button onClick={() => deletePost(post._id)}>Delete Post</button>
            <hr></hr>
          </div>
        );
      })}
    </div>
  ) : (
    <p>{status}</p>
  );
};

export default MyProfile;
