// import logo from './logo.svg';
// import './App.css';
import React, { useEffect } from "react";
import axios from "axios";
import Chart from "react-apexcharts";
import Posts from "./Posts";
import News from "./News";
import { Link, useParams } from "react-router-dom";
import { get, post, remove } from "../services/service";
import * as dayjs from "dayjs";
import Login from "./Login";
import Button from "@mui/material/Button";
import Watchlist from "./Watchlist";
import Summary from "./Summary";

function StockChart(props) {
  // console.log(props.input);
  const [name, setName] = React.useState("");
  const [ticker, setTicker] = React.useState("");
  const [currentPrice, setCurrentPrice] = React.useState("");
  const [previousClose, setPreviousClose] = React.useState("");
  const [series, setSeries] = React.useState([{ data: [] }]);
  const [timespan, setTimespan] = React.useState("1d");
  const [slices, setSlices] = React.useState([0, 0]);
  const [watchlist, setWatchlist] = React.useState([]);
  const [status, setStatus] = React.useState();
  const [selected, setSelected] = React.useState("News");

  // const params = useParams();

  const displayData = async () => {
    // console.log(props.input);
    let response = await get(`/data/${props.input}/${timespan}`);
    let response2 = await get(`/details/${props.input}`);

    setName(response2.data.quoteResponse.result[0].longName);

    setTicker(response.data.chart.result[0].meta.symbol);
    props.ticker(response.data.chart.result[0].meta.symbol);

    timespan === "1d" ? setSlices([6, 12]) : setSlices([0, 6]);

    const quote = response.data.chart.result[0].indicators.quote[0];

    const prices = response.data.chart.result[0].timestamp.map(
      (timestamp, index) => ({
        x: new Date(timestamp * 1000),
        y: [
          quote.open[index],
          quote.high[index],
          quote.low[index],
          quote.close[index],
        ].map((number) => {
          return number ? +number.toFixed(2) : null;
        }),
      })
    );

    setSeries([
      {
        data: prices,
      },
    ]);
  };

  async function getStonks() {
    // console.log(props.input);
    const response3 = await get(`/data/${props.input}/${timespan}`);
    return response3;
  }
  // console.log(currentPrice);
  // console.log(params.input);

  // let timeoutId;
  async function getLatestPrice() {
    try {
      const data = await getStonks();
      setCurrentPrice(
        data.data.chart.result[0].meta.regularMarketPrice.toFixed(2)
      );
      setPreviousClose(
        data.data.chart.result[0].meta.chartPreviousClose.toFixed(2)
      );
      // console.log(data);
    } catch (err) {
      console.log(err);
    }
    // timeoutId = setTimeout(getLatestPrice, 1000);
  }
  // getLatestPrice();

  // useEffect(() => {
  //   // console.log("tick");
  //   // console.log(params.input);

  //   const intervalCall = setInterval(() => {
  //     getLatestPrice();
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalCall);
  //   };
  // }, []);

  // useEffect(() => {
  // displayData();
  // getLatestPrice();
  // }, [props, timespan]);

  const chart = {
    options: {
      chart: {
        type: "candlestick",
        // height: 350,
      },

      xaxis: {
        type: "category",
        labels: {
          rotate: 0,
          showDuplicates: false,
          formatter: function (val) {
            return dayjs(val)
              .format("MMM DD HH:mm")
              .substring(slices[0], slices[1]);
          },
        },
        tickAmount: 6,
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
  };

  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  useEffect(() => {
    if (props.input.length !== 0) {
      getLatestPrice();
      displayData();
      const interval = setInterval(() => {
        getLatestPrice();
        displayData();
      }, 6000000);
      return () => clearInterval(interval);
    }
  }, [props.input, timespan]);

  // useEffect(() => {
  //   console.log("update watchlist");
  //   updateWatchlist();
  // }, [, watchlist]);

  // const fetchUser = async () => {
  //   if (token) {
  //     try {
  //       const response = await get(`/users/my-profile`);
  //       setWatchlist(response.data.watchlist);
  //     } catch (err) {
  //       setStatus(err.message);
  //     }
  //   }
  // };

  // const updateWatchlist = async (array) => {
  //   let updatedUser = await post("/users/update-watchlist", {
  //     watchlist: array,
  //   });
  // };

  // const addToWatchlist = () => {
  //   if (watchlist.includes(ticker)) {
  //     const index = watchlist.indexOf(ticker);
  //     const newWatchlist_1 = [...watchlist];
  //     newWatchlist_1.splice(index, 1);
  //     setWatchlist(newWatchlist_1);
  //     updateWatchlist(newWatchlist_1);
  //   } else {
  //     const newWatchlist_2 = watchlist.concat([ticker]);
  //     setWatchlist(newWatchlist_2);
  //     updateWatchlist(newWatchlist_2);
  //   }
  // };

  return (
    <div className="stock-chart">
      <div className="stock-top">
        <div className="stock-header">
          <div className="stock-title">
            <div className="stock-name">
              {name}
              <h1>
                {currentPrice && ticker} <span>{currentPrice}</span>
                <span
                  className="price-change"
                  style={
                    (currentPrice - previousClose).toFixed(2) < 0
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {(currentPrice - previousClose).toFixed(2)} (
                  {(
                    ((currentPrice - previousClose).toFixed(2) /
                      previousClose) *
                    100
                  ).toFixed(2)}
                  %)
                </span>
              </h1>
            </div>
          </div>
          <div className="chart-container">
            {/* {series && (
              <Chart
                options={chart.options}
                series={series}
                type="candlestick"
                width="200%"
                height={320}
              />
            )} */}
          </div>
          <div className="button-container">
            <button
              onClick={() => setTimespan("1d")}
              style={
                timespan === "1d"
                  ? {
                      color: "#F9F9F9",
                      backgroundColor: "#076AE0",
                      borderRadius: "2px",
                    }
                  : {
                      color: "#076AE0",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "2px",
                    }
              }
            >
              D
            </button>
            <button
              onClick={() => setTimespan("1wk")}
              style={
                timespan === "1wk"
                  ? {
                      color: "#F9F9F9",
                      backgroundColor: "#076AE0",
                      borderRadius: "2px",
                    }
                  : {
                      color: "#076AE0",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "2px",
                    }
              }
            >
              W
            </button>
            <button
              onClick={() => setTimespan("1mo")}
              style={
                timespan === "1mo"
                  ? {
                      color: "#F9F9F9",
                      backgroundColor: "#076AE0",
                      borderRadius: "2px",
                    }
                  : {
                      color: "#076AE0",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "2px",
                    }
              }
            >
              M
            </button>
            <button
              onClick={() => setTimespan("6mo")}
              style={
                timespan === "6mo"
                  ? {
                      color: "#F9F9F9",
                      backgroundColor: "#076AE0",
                      borderRadius: "2px",
                    }
                  : {
                      color: "#076AE0",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "2px",
                    }
              }
            >
              6M
            </button>
            <button
              onClick={() => setTimespan("1y")}
              style={
                timespan === "1y"
                  ? {
                      color: "#F9F9F9",
                      backgroundColor: "#076AE0",
                      borderRadius: "2px",
                    }
                  : {
                      color: "#076AE0",
                      backgroundColor: "#F9F9F9",
                      borderRadius: "2px",
                    }
              }
            >
              Y
            </button>
          </div>
        </div>
        {/* <Watchlist watchlist={watchlist} /> */}
      </div>
      {/* <div className="button-container">
        <button
          onClick={() => setSelected("News")}
          style={
            selected === "News"
              ? {
                  color: "#F9F9F9",
                  backgroundColor: "#076AE0",
                  borderRadius: "2px",
                }
              : {
                  color: "#076AE0",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "2px",
                }
          }
        >
          News
        </button>
        <button
          onClick={() => setSelected("Posts")}
          style={
            selected === "Posts"
              ? {
                  color: "#F9F9F9",
                  backgroundColor: "#076AE0",
                  borderRadius: "2px",
                }
              : {
                  color: "#076AE0",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "2px",
                }
          }
        >
          Discussion
        </button>
      </div>
      {selected === "News" && <News />}
      {selected === "Posts" && <Posts ticker={ticker} />} */}
    </div>
  );
}

export default StockChart;
