import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

function Trade() {
  return <div className="paper-trading">Trade</div>;
}

export default Trade;
