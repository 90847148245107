import React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { ChartComponent } from "./ChartComponent";

function Portfolio() {
  const data = [
    { value: 0, time: 1642425322 },
    { value: 8, time: 1642511722 },
    { value: 10, time: 1642598122 },
    { value: 20, time: 1642684522 },
    { value: 3, time: 1642770922 },
    { value: 43, time: 1642857322 },
    { value: 41, time: 1642943722 },
    { value: 43, time: 1643030122 },
    { value: 56, time: 1643116522 },
    { value: 46, time: 1643202922 },
  ];
  return (
    <div className="portfolio">
      <div className="portfolio-left">
        <h2 className="tab-heading">Portfolio</h2>
        <h5>Account Information</h5>
        <h4 className="h4-portfolio">1,000,000</h4>
        <p className="account-value">Account Value (USD)</p>
        <div className="quick-price">
          <p
            className="secondary-price"
            style={1 > 0 ? { color: "#068B03" } : { color: "#F42727" }}
          >
            2.54
          </p>
          <p
            className="secondary-price"
            style={1 > 0 ? { color: "#068B03" } : { color: "#F42727" }}
          >
            1.56%
          </p>
        </div>
        <div className="account-value-summary">
          <p>
            <span>Positions</span>
            <span>900,000</span>
          </p>
          <p>
            <span>Buying Power</span>
            <span>100,000</span>
          </p>
        </div>
      </div>
      <div className="portfolio-right">
        <div className="chart">
          <ChartComponent
            data={data}
            type={"area"}
            // data={initialData}
          ></ChartComponent>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
