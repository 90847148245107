import React from "react";
import axios from "axios";
import logo from "../assets/logo.png";
import defaultImage from "../assets/default.png";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { get, post, remove } from "../services/service";
import Sidebar from "./Sidebar";
import Watchlist from "./Watchlist";
import StockChart from "./StockChart";
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import Portfolio from "./Portfolio";
import Analytics from "./Analytics";
import Trade from "./Trade";

function Dashboard() {
  const params = useParams();
  const navigate = useNavigate();
  const tabList = ["portfolio", "watchlist", "analytics", "trade", "contact"];

  const token = localStorage.getItem("token");

  React.useEffect(() => {
    // console.log(params);
    if (!tabList.includes(params.tab)) {
      navigate("/dashboard/portfolio");
    }
    if (!token) {
      navigate("/");
    }
  }, []);

  const [input, setInput] = React.useState("");
  const [data, setData] = React.useState("");

  const search = (text) => {
    setInput(text);
  };

  const ticker = (data) => {
    setData(data);
  };

  if (token) {
    return (
      <div className="dashboard">
        <Sidebar tab={params.tab} />
        <div className="dashboard-content-container">
          {params.tab === "portfolio" && <Portfolio />}
          {params.tab === "watchlist" && <Watchlist />}
          {params.tab === "analytics" && <Analytics />}
          {params.tab === "trade" && <Trade />}
        </div>
      </div>
    );
  }
}

export default Dashboard;
