import React from "react";
import axios from "axios";
import { Routes, Route, Link, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import { borderBottom, borderBottomColor, fontSize } from "@mui/system";
import { get, post, remove } from "../services/service";
import { createChart, ColorType } from "lightweight-charts";
import { ChartComponent } from "./ChartComponent";

function Chart(props) {
  const [timespan, setTimespan] = React.useState("1d");
  const [historical, setHistorical] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  //   const {
  //     data,
  //     colors: {
  //       backgroundColor = "white",
  //       lineColor = "#2962FF",
  //       textColor = "black",
  //       areaTopColor = "#2962FF",
  //       areaBottomColor = "rgba(41, 98, 255, 0.28)",
  //     } = {},
  //   } = props;

  //   const chartContainerRef = React.useRef();
  //   console.log(chartContainerRef.current.clientHeight);

  //   React.useEffect(() => {
  //     const handleResize = () => {
  //       chart.applyOptions({ width: chartContainerRef.current.clientWidth });
  //     };

  //     const chart = createChart(chartContainerRef.current, {
  //       layout: {
  //         background: { type: ColorType.Solid, color: backgroundColor },
  //         textColor,
  //       },
  //       width: chartContainerRef.current.clientWidth,
  //       height: 300,
  //     });
  //     chart.timeScale().fitContent();

  //     const newSeries = chart.addAreaSeries({
  //       lineColor,
  //       topColor: areaTopColor,
  //       bottomColor: areaBottomColor,
  //     });
  //     newSeries.setData(data);

  //     window.addEventListener("resize", handleResize);

  //     return () => {
  //       window.removeEventListener("resize", handleResize);

  //       chart.remove();
  //     };
  //   }, [
  //     data,
  //     backgroundColor,
  //     lineColor,
  //     textColor,
  //     areaTopColor,
  //     areaBottomColor,
  //   ]);

  //   window.onload = () => {
  //     const candlestickSeries = chart.addCandlestickSeries({
  //       upColor: "#26a69a",
  //       downColor: "#ef5350",
  //       borderVisible: false,
  //       wickUpColor: "#26a69a",
  //       wickDownColor: "#ef5350",
  //     });
  //     const chartOptions = {
  //       layout: {
  //         textColor: "black",
  //         background: { type: "solid", color: "white" },
  //       },
  //     };

  //     const chart = createChart(
  //       document.getElementById("stockchart"),
  //       chartOptions
  //     );
  //     const data = [
  //       { open: 10, high: 10.63, low: 9.49, close: 9.55, time: 1642427876 },
  //       { open: 9.55, high: 10.3, low: 9.42, close: 9.94, time: 1642514276 },
  //       { open: 9.94, high: 10.17, low: 9.92, close: 9.78, time: 1642600676 },
  //       { open: 9.78, high: 10.59, low: 9.18, close: 9.51, time: 1642687076 },
  //       { open: 9.51, high: 10.46, low: 9.1, close: 10.17, time: 1642773476 },
  //       { open: 10.17, high: 10.96, low: 10.16, close: 10.47, time: 1642859876 },
  //       { open: 10.47, high: 11.39, low: 10.4, close: 10.81, time: 1642946276 },
  //       { open: 10.81, high: 11.6, low: 10.3, close: 10.75, time: 1643032676 },
  //       { open: 10.75, high: 11.6, low: 10.49, close: 10.93, time: 1643119076 },
  //       { open: 10.93, high: 11.53, low: 10.76, close: 10.96, time: 1643205476 },
  //     ];

  //     candlestickSeries.setData(data);

  //     chart.timeScale().fitContent();
  //   };

  async function getHistory() {
    if (props.placeholderStats.symbol) {
      try {
        const response = await get(
          `/data/${props.placeholderStats.symbol}/${timespan}`
        );
        setHistorical(response);

        // console.log(response.data.chart.result[0].indicators.quote[0].close);

        const timestamps = response.data.chart.result[0].timestamp;
        const quotes = response.data.chart.result[0].indicators.quote[0];

        const prices = timestamps.map((timestamp, index) => {
          return {
            time: timestamp,
            open: quotes.open[index]
              ? +quotes.open[index].toFixed(2)
              : undefined,
            high: quotes.high[index]
              ? +quotes.high[index].toFixed(2)
              : undefined,
            low: quotes.low[index] ? +quotes.low[index].toFixed(2) : undefined,
            close: quotes.close[index]
              ? +quotes.close[index].toFixed(2)
              : undefined,
          };
        });
        // console.log(timestamps);
        setSeries(prices);
      } catch (err) {
        console.log(err.message);
      }
    }
  }

  async function getHistoricalData() {
    try {
      const data = await getHistory();
    } catch (err) {
      console.log(err);
    }
  }

  React.useEffect(() => {
    getHistoricalData();
    // console.log(historical);
  }, [props.placeholderStats.symbol, timespan]);

  return (
    <div className="chart">
      <div className="chart-quote">
        <span className="chart-shortname">
          {props.placeholderStats.shortName}
        </span>
        <div className="symbol-price">
          <h4 className="h4-watchlist">{props.placeholderStats.symbol}</h4>
          {props.placeholderStats.regularMarketChange ? (
            <div className="chart-quick-price">
              <p
                className="chart-main-price"
                style={
                  props.placeholderStats.regularMarketChange > 0
                    ? { color: "#068B03" }
                    : { color: "#F42727" }
                }
              >
                {props.placeholderStats.regularMarketPrice.toFixed(2)}
              </p>
              <p
                className="chart-secondary-price"
                style={
                  props.placeholderStats.regularMarketChange > 0
                    ? { color: "#068B03" }
                    : { color: "#F42727" }
                }
              >
                {props.placeholderStats.regularMarketChange.toFixed(2)}
              </p>
              <p
                className="chart-secondary-price"
                style={
                  props.placeholderStats.regularMarketChange > 0
                    ? { color: "#068B03" }
                    : { color: "#F42727" }
                }
              >
                ({props.placeholderStats.regularMarketChangePercent.toFixed(2)}
                %)
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="chart-buttons">
        <button
          onClick={() => setTimespan("1d")}
          style={
            timespan === "1d"
              ? {
                  borderBottom: "4px",
                  borderBottomColor: "#0B5DD7",
                  borderBottomStyle: "solid",
                }
              : {}
          }
        >
          D
        </button>
        <button
          onClick={() => setTimespan("1wk")}
          style={
            timespan === "1wk"
              ? {
                  borderBottom: "4px",
                  borderBottomColor: "#0B5DD7",
                  borderBottomStyle: "solid",
                }
              : {}
          }
        >
          W
        </button>
        <button
          onClick={() => setTimespan("1mo")}
          style={
            timespan === "1mo"
              ? {
                  borderBottom: "4px",
                  borderBottomColor: "#0B5DD7",
                  borderBottomStyle: "solid",
                }
              : {}
          }
        >
          M
        </button>
        <button
          onClick={() => setTimespan("6mo")}
          style={
            timespan === "6mo"
              ? {
                  borderBottom: "4px",
                  borderBottomColor: "#0B5DD7",
                  borderBottomStyle: "solid",
                }
              : {}
          }
        >
          6M
        </button>
        <button
          onClick={() => setTimespan("1y")}
          style={
            timespan === "1y"
              ? {
                  borderBottom: "4px",
                  borderBottomColor: "#0B5DD7",
                  borderBottomStyle: "solid",
                }
              : {}
          }
        >
          Y
        </button>
      </div>
      <ChartComponent
        data={series}
        type={"candlestick"}
        // data={initialData}
      ></ChartComponent>
      {/* {...props} */}
    </div>
  );
}

export default Chart;
