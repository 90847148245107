import React from "react";
import axios from "axios";
import logo from "../assets/logo.png";
import defaultImage from "../assets/default.png";
import defaultImage2 from "../assets/default2.png";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { get, post, remove } from "../services/service";

// const useOutsideClick = (callback) => {
//   const ref = React.useRef();

//   React.useEffect(() => {
//     const sanitize = (event) => {
//       if (ref.current && !ref.current.contains(event.target)) {
//         callback();
//       }
//     };

//     document.addEventListener("click", sanitize);

//     return () => {
//       document.removeEventListener("click", sanitize);
//     };
//   }, [ref]);

//   return ref;
// };

function Navbar(props) {
  const [text, setText] = React.useState("");
  const [foundQuotes, setFoundQuotes] = React.useState({
    symbol: [],
    shortname: [],
  });
  const [shortname, setShortname] = React.useState([]);
  const [symbol, setSymbol] = React.useState([]);

  const navigate = useNavigate();

  // const sanitize = (ticker) => {
  //   if (ticker) {
  //     props.search(ticker);
  //     setText("");
  //     navigate(`/stockchart/${ticker}`);
  //   } else if (text) {
  //     props.search(text);
  //     setText("");
  //     navigate(`/stockchart/${text}`);
  //   }
  // };

  // const handleClickOutside = () => {
  //   setText("");
  // };

  // const ref = useOutsideClick(handleClickOutside);

  const token = localStorage.getItem("token");
  // const profilePicture = localStorage.getItem("profilePicture");

  // function onlySpaces(str) {
  //   return /^\s*$/.test(str);
  // }

  // async function findStocks(input) {
  //   setText(input);
  //   if (!onlySpaces(input)) {
  //     try {
  //       const response = await get(`/search/${input}`);
  //       setFoundQuotes(response.data.quotes);
  //       return response;
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }

  const openLogin = () => {
    props.openlogin(true);
  };

  return (
    <nav className="navbar">
      <Link to={token ? "/dashboard/portfolio" : "/"} className="logo">
        <img src={logo} height="40px" width="197px" alt="logo" />
      </Link>

      {/* <div className="search" ref={ref}>
        <input
          type="text"
          placeholder="Search company or symbol"
          value={text}
          onChange={(e) => findStocks(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" ? sanitize(e.target.value) : null
          }
        />
        {text && (
          <div className="dropdown">
            {foundQuotes.length > 0 &&
              foundQuotes.map((index) => {
                return (
                  <div
                    className="dropdown-row"
                    key={index.symbol}
                    onClick={() => sanitize(index.symbol)}
                  >
                    <div style={{ fontWeight: "bold" }}>{index.symbol}</div>
                    <div>{index.shortname}</div>
                  </div>
                );
              })}
          </div>
        )}
      </div> */}

      {/* <div className="log">
        <Link to={token ? "/analytics" : "/login"}>Analytics</Link>
        <Link to="/paper-trading">Paper Trading</Link>
        {token && (
          <Link to="/my-profile" className="profile">
            <img
              src={profilePicture || defaultImage}
              alt="profile"
              height="50px"
              width="50px"
              className="roundImage"
            />
          </Link>
        )}
        {!token && (
          <button className="signin-button">
            <Link to="/login">Sign In</Link>
          </button>
        )}
      </div> */}
      {/* <div className="log"> */}
      <Link
        to={token ? "/my-profile" : "#"}
        className="log"
        onClick={token ? "" : openLogin}
      >
        <img
          src={token ? defaultImage2 : defaultImage}
          alt="profile"
          height="40px"
          width="40px"
        />
      </Link>
      {/* </div> */}
    </nav>
  );
}

export default Navbar;
