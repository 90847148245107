import React from "react";
// import axios from "axios";
// import { Routes, Route, Link, useSearchParams } from "react-router-dom";
// import Box from "@mui/material/Box";
// import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Paper from "@mui/material/Paper";
// import Divider from "@mui/material/Divider";
// import ListItemButton from "@mui/material/ListItemButton";
// import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import star from "../assets/star.png";
import starselected from "../assets/starselected.png";
import { get, post, remove } from "../services/service";
import Searchbar from "./Searchbar";
import Chart from "./Chart";
import News from "./News";

function Watchlist() {
  const [current, setCurrent] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState("");
  const [watchlist, setWatchlist] = React.useState([]);
  const [recent, setRecent] = React.useState([]);
  const [stats, setStats] = React.useState([]);
  const [placeholderStats, setPlaceholderStats] = React.useState([]);
  const [alert, setAlert] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [recentStats, setRecentStats] = React.useState([]);
  // const [lastClicked, setLastClicked] = React.useState("");

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  React.useEffect(() => {
    // console.log(placeholder);
    fetchUser();
    const currentClick = localStorage.getItem("current");
    if (currentClick) {
      setCurrent(currentClick);
    } else {
      setCurrent("watchlist");
    }
    // console.log(props.ticker);
    // console.log("iashdiasuhdisua");
    // if (props.ticker && loading === false) {
    // console.log("here");
    // verify(props.ticker);
    // }
  }, []);

  React.useEffect(() => {
    updateWatchlist();
  }, [watchlist]);

  const watchlistClick = (value) => {
    setPlaceholder(value);
    localStorage.setItem("currentStock", value);
    // window.history.pushState(value, "Ticker", `/dashboard/watchlist/${value}`);
  };

  const currentClick = (value) => {
    setCurrent(value);
    localStorage.setItem("current", value);
    // window.history.pushState(value, "Ticker", `/dashboard/watchlist/${value}`);
  };

  const fetchUser = async () => {
    const currentStock = localStorage.getItem("currentStock");
    if (token) {
      try {
        // console.log("fetching");
        const response = await get(`/users/my-profile`);
        if (response.data.watchlist.length !== 0 && currentStock) {
          setWatchlist(response.data.watchlist);
          setPlaceholder(currentStock);
        } else if (response.data.watchlist.length !== 0 && !currentStock) {
          setWatchlist(response.data.watchlist);
          setPlaceholder(response.data.watchlist[0]);
        } else if (response.data.watchlist.length === 0 && currentStock) {
          setPlaceholder(currentStock);
        }
        // } else if (){
        //   console.log("no current stock");
        //   setPlaceholder(response.data.watchlist[0]);
        // }

        // if (currentStock) {
        //   console.log("here");
        //   setPlaceholder(currentStock);
        // } else {
        //   setPlaceholder(response.data.watchlist[0]);
        // }
        // else {
        //   if (localStorage.getItem("currentStock").length !== 0) {
        //     console.log("here");
        //     setPlaceholder(localStorage.getItem("currentStock"));
        //   } else {
        //     console.log("heretooo");
        //     setPlaceholder(response.data.watchlist[0]);
        //   }
        // }
        // if (props.ticker) {
        //   // console.log(props.ticker);
        //   verify(props.ticker);
        // } else {
        //   setPlaceholder(response.data.watchlist[0]);
        // }

        // if (props.ticker) {
        //   verify(props.ticker);
        // }
        setRecent(response.data.recent);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const updateWatchlist = async (array) => {
    let updatedUser = await post("/users/update-watchlist", {
      watchlist: array,
    });
  };

  const updateRecent = async (array) => {
    let updatedUser = await post("/users/update-recent", {
      recent: array,
    });
  };

  const handleAlert = () => {
    if (alert === false) {
      setAlert(true);

      setTimeout(() => {
        setAlert(false);
      }, 5000);
    }
  };

  const addToWatchlist = () => {
    if (watchlist.includes(placeholder)) {
      const index = watchlist.indexOf(placeholder);
      const newWatchlist_1 = [...watchlist];
      newWatchlist_1.splice(index, 1);
      setWatchlist(newWatchlist_1);
      updateWatchlist(newWatchlist_1);
    } else if (watchlist.length >= 5) {
      handleAlert();
    } else {
      const newWatchlist_2 = [placeholder].concat(watchlist);
      setWatchlist(newWatchlist_2);
      updateWatchlist(newWatchlist_2);
    }
  };

  const addToRecent = (value) => {
    if (recent.includes(value)) {
      const index = recent.indexOf(value);
      const newRecent = [...recent];
      newRecent.splice(index, 1);
      newRecent.unshift(value);
      if (newRecent.length > 5) {
        newRecent.pop();
      }
      setRecent(newRecent);
      updateRecent(newRecent);
    } else {
      const newRecent_2 = [...recent];
      newRecent_2.unshift(value);
      if (newRecent_2.length > 5) {
        newRecent_2.pop();
      }
      setRecent(newRecent_2);
      updateRecent(newRecent_2);
    }
  };

  async function getStonks() {
    let currentArray;
    if (current === "watchlist") {
      currentArray = watchlist;
    } else if (current === "recent") {
      currentArray = recent;
    }
    try {
      if (currentArray.includes(placeholder)) {
        const response = await get(`/data/watchlist/${currentArray}`);
        const i = response.data.quoteResponse.result.findIndex(
          (e) => e.symbol === placeholder
        );
        setPlaceholderStats(response.data.quoteResponse.result[i]);
        setStats(response.data.quoteResponse.result);
      } else if (!currentArray.includes(placeholder) && placeholder) {
        const response = await get(
          `/data/watchlist/${currentArray.concat(placeholder)}`
        );
        setPlaceholderStats(response.data.quoteResponse.result.pop());
        setStats(response.data.quoteResponse.result);
      } else if (!currentArray.includes(placeholder) && !placeholder) {
        const response = await get(`/data/watchlist/${currentArray}`);
        setStats(response.data.quoteResponse.result);
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  async function getLatestPrice() {
    try {
      const data = await getStonks();
    } catch (err) {
      console.log(err);
    }
  }

  async function verify(value) {
    const upper = value.toUpperCase();
    try {
      const response = await get(`/data/watchlist/${upper}`);
      if (response.data.quoteResponse.result.length !== 0) {
        setPlaceholder(upper);
        addToRecent(upper);
        localStorage.setItem("currentStock", upper);
        // console.log(upper);
        // console.log(props.ticker === placeholder);
        // if (props.ticker !== placeholder) {
        //   // console.log("no props");
        //   addToRecent(upper);
        // }
        // window.history.pushState(
        //   upper,
        //   "Ticker",
        //   `/dashboard/watchlist/${upper}`
        // );
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err.message);
      return false;
    }
  }

  React.useEffect(() => {
    if (
      (watchlist.length !== 0 && current === "watchlist") ||
      (recent.length !== 0 && current === "recent") ||
      placeholder.length !== 0
    ) {
      getLatestPrice();
      const interval = setInterval(() => {
        getLatestPrice();
      }, 5000000);
      return () => clearInterval(interval);
    }
  }, [current, watchlist, recent, placeholder]);

  if (loading === false) {
    return (
      <div className="watchlist">
        <div className="watchlist-left">
          <h2 className="tab-heading">Watchlist</h2>
          <div className="watchlist-search">
            <Searchbar
              input={verify}
              placeholderStats={placeholderStats}
              placeholder={placeholder}
            />
            <img
              onClick={() => addToWatchlist([placeholder])}
              src={watchlist.includes(placeholder) ? starselected : star}
              height="32.36px"
              width="33.11px"
              alt="star"
            />
          </div>
          <div className="watchlist-button-container">
            <button
              onClick={(e) => currentClick(e.target.value)}
              className={
                current === "watchlist"
                  ? "watchlist-button-selected"
                  : "watchlist-button"
              }
              value="watchlist"
            >
              Watchlist
            </button>
            <button
              onClick={(e) => currentClick(e.target.value)}
              className={
                current === "recent"
                  ? "watchlist-button-selected"
                  : "watchlist-button"
              }
              value="recent"
            >
              Recent
            </button>
          </div>
          <div className="list-container">
            {(watchlist.length !== 0 && current === "watchlist") ||
            (recent.length !== 0 && current === "recent")
              ? stats.map((item) => (
                  <div
                    className={"watchlist-row-element"}
                    key={item.symbol}
                    // onClick={() => setPlaceholder(item.symbol)}
                    onClick={() => watchlistClick(item.symbol)}
                    // onClick={() =>
                    //   window.history.pushState(
                    //     item.symbol,
                    //     "Ticker",
                    //     `/dashboard/watchlist/${item.symbol}`
                    //   )
                    // }
                    style={
                      placeholder === item.symbol
                        ? { backgroundColor: "#FFFFFF" }
                        : { backgroundColor: "" }
                    }
                  >
                    <div className="watchlist-row-subelement-left">
                      <span className="subelement-ticker">{item.symbol}</span>
                      <span className="subelement-shortname">
                        {item.shortName}
                      </span>
                    </div>
                    <div className="watchlist-row-subelement-right">
                      <span className="subelement-price">
                        {item.regularMarketPrice.toFixed(2)}
                      </span>
                      <span
                        className="subelement-change"
                        style={
                          item.regularMarketChange > 0
                            ? { color: "#068B03" }
                            : { color: "#F42727" }
                        }
                      >
                        {item.regularMarketChange.toFixed(2)} (
                        {item.regularMarketChangePercent.toFixed(2)}%)
                      </span>
                    </div>
                  </div>
                ))
              : ""}
            {watchlist.length === 0 && current === "watchlist" ? (
              <p>
                Start your watchlist by searching any stock and selecting the
                star to the right
              </p>
            ) : (
              ""
            )}
            {recent.length === 0 && current === "recent" ? (
              <p>Your recent searches will appear here</p>
            ) : (
              ""
            )}
            {alert && (
              <p style={{ color: "#F42727" }}>
                Please limit your watchlist to 5 stocks for a better experience
              </p>
            )}
          </div>
        </div>
        {placeholder && (
          <div className="watchlist-right">
            <Chart placeholderStats={placeholderStats} />
            <News ticker={placeholder} />
          </div>
        )}
      </div>
    );
  }
}

export default Watchlist;
