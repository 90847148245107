import React from "react";
import axios from "axios";
import { get_analytics, getAnalytics } from "../services/service";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { get, post, remove } from "../services/service";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { color } from "@mui/system";
import { useParams, useNavigate } from "react-router-dom";
import { formControlLabelClasses } from "@mui/material";

function Analytics() {
  const [tickers, setTickers] = React.useState([]);
  const [text, setText] = React.useState("");
  const [foundQuotes, setFoundQuotes] = React.useState({
    symbol: [],
    shortname: [],
  });
  const [options, setOptions] = React.useState([]);
  const [chipLength, setChipLength] = React.useState(1);
  const [status, setStatus] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [interval, setInterval] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    let token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    // getAnalyticsApi();
  }, []);

  // const getAnalyticsApi = async () => {
  //   try {
  //     const response = await get_analytics_v2(`/api/analytics/${string}`);
  //     console.log(response);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  async function fetchData(e, tickers, startDate, endDate, interval) {
    setStatus("");
    e.preventDefault();
    if (tickers.length && startDate && endDate && interval) {
      setLoading(true);
      // try {
      //   if (tickers.length > 0) {
      //     let string = tickers.join(" ");
      //     const response = await get_analytics_v2(
      //       `/api/analytics/${string}/start=${startDate}/end=${endDate}/interval=${interval}`
      //     );
      //     console.log(response);
      //     const blob = new Blob([response.data], {
      //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      //     });
      //     download(blob, `marketchamp_report.xlsx`);
      //     setLoading(false);
      //     return response;
      //   } else {
      //     setLoading(false);
      //     setStatus("Please enter valid information");
      //   }
      // } catch (err) {
      //   setLoading(false);
      //   console.log(err.message);
      // }
      let string = tickers.join(" ");
      getAnalytics(string, startDate, endDate, interval)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "marketchamp_report.xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => console.error("Error:", error));

      // try {
      //   if (tickers.length > 0) {
      //     let string = tickers.join(" ");
      //     const response = await get_analytics(
      //       `/analytics/${string}?start=${startDate}&end=${endDate}&interval=${interval}`
      //     );
      //     setLoading(false);
      //     return response
      //       .blob()
      //       .then((blob) => download(blob, `marketchamp_report.xlsx`));
      //   } else {
      //     setLoading(false);
      //     setStatus("Please enter valid information");
      //   }
      // } catch (err) {
      //   setLoading(false);
      //   setStatus("Please try again");
      // }
    } else {
      setLoading(false);
      setStatus("Please enter valid information");
    }
    setLoading(false);
  }

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  function onlySpaces(str) {
    return /^\s*$/.test(str);
  }

  async function findStocks(input) {
    setText(input);
    if (!onlySpaces(input)) {
      try {
        const response = await get(`/search/${input}`);
        setFoundQuotes(response.data.quotes);
        const symbols = response.data.quotes.map((index) => {
          return index.symbol;
        });
        setOptions(symbols);
        return response;
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleChipChange = (value) => {
    setTickers(value);
    if (Math.abs(chipLength - value.length) > 1) {
      setChipLength(value.length + 1);
    }
    if (chipLength === value.length) {
      setText("");
      setChipLength(value.length + 1);
    }
  };

  const handleStartChange = (newValue) => {
    setStartDate(convert(newValue));
  };
  const handleEndChange = (newValue) => {
    setEndDate(convert(newValue));
  };

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleIntervalChange = (newValue) => {
    setInterval(newValue);
  };

  return (
    <div className="analytics">
      <h1>Portfolio Analysis</h1>

      <form
        onSubmit={(e) => fetchData(e, tickers, startDate, endDate, interval)}
        className="analytics-form"
      >
        <div className="form-container">
          <div className="form-container-input">
            <Stack sx={{ width: 230 }}>
              <Autocomplete
                onChange={(event, value) => handleChipChange(value)}
                multiple
                id="tags-filled"
                options={text && options ? options : []}
                defaultValue={[]}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="filled"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Symbols"
                    value={text}
                    onChange={(e) => findStocks(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && e.preventDefault()}
                  />
                )}
              />
            </Stack>
          </div>
          <div className="form-container-input-middle">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3} width={150}>
                <DesktopDatePicker
                  disableFuture
                  label="Start Date"
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={handleStartChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                  disableFuture
                  minDate={startDate}
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  value={endDate}
                  onChange={handleEndChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </LocalizationProvider>
          </div>
          <div className="form-container-input">
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={interval}
                label="Interval"
                onChange={(e) => handleIntervalChange(e.target.value)}
              >
                <MenuItem value={"1d"}>Day</MenuItem>
                <MenuItem value={"1wk"}>Week</MenuItem>
                <MenuItem value={"1mo"}>Month</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div>
          <button type="submit" className="form-button" disabled={loading}>
            Submit
          </button>
        </div>
      </form>
      <p style={{ color: "red" }}>{status}</p>
    </div>
  );
}

export default Analytics;
