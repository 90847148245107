import React from "react";
import axios from "axios";
import {
  Routes,
  Route,
  Link,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
// import wallSt from "../assets/wallStreet.jpg";
import { get, post, remove } from "../services/service";
import background from "../assets/background.png";
import boy from "../assets/boy.png";
import girl from "../assets/girl.png";
import Footer from "./Footer";
import Login from "./Login";
import Signup from "./Signup";

function Home(props) {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("username");
  const [isOpen, setIsOpen] = React.useState(false);
  const [which, setWhich] = React.useState("");
  const [openlogin, setOpenlogin] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const navigate = useNavigate();

  const loginCheck = async () => {
    try {
      let response = await get("/users/login-test");
      console.log(response.data.message);
      navigate("/dashboard/portfolio");
      setLoading(false);
    } catch (err) {
      if (err.response.status === 440 || err.response.status === 400) {
        console.log(err.response.status);
        localStorage.clear();
        setLoading(false);
      }
    }
  };

  React.useEffect(function () {
    loginCheck();
  }, []);

  let timeout;
  clearInterval(timeout);

  const openSignup = () => {
    setIsOpen(true);
    setWhich("signup");
  };
  const click = (data) => {
    setIsOpen(data);
    props.closelogin(data);
    if (data === false) {
      setWhich("");
    }
  };

  const clack = (data) => {
    setWhich(data);
  };

  React.useEffect(() => {
    setIsOpen(props.openlogin);
    if (props.openlogin === true) {
      setWhich("login");
    }
  }, [props.openlogin]);

  if (loading === false) {
    return (
      <div className="home">
        <h1>Make Smart, Data driven decisions!</h1>
        <h4>Paper trade with the latest Market Data</h4>

        <button onClick={openSignup} className="signin-button">
          Try Today!
        </button>
        {isOpen && which === "signup" ? (
          <Signup open={click} form={clack} />
        ) : (
          ""
        )}
        {isOpen && which === "login" ? <Login open={click} form={clack} /> : ""}
        {openlogin ? <Login open={click} form={clack} /> : ""}
        {openlogin && which === "signup" ? (
          <Signup open={click} form={clack} />
        ) : (
          ""
        )}
        {isOpen ? <div className="faded-background"></div> : ""}
        {openlogin ? <div className="faded-background"></div> : ""}

        <div className="image-container">
          <img
            src={background}
            alt="background"
            height="979px"
            width="1326px"
            className="background-image"
          />
          <img
            src={boy}
            alt="boy"
            height="662.91px"
            width="200px"
            className="boy-image"
          />
          <img
            src={girl}
            alt="girl"
            height="539.61px"
            width="200px"
            className="girl-image"
          />
        </div>
      </div>
    );
  }
}

export default Home;
