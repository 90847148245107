import React from "react";
import axios from "axios";
import {
  Routes,
  Route,
  Link,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";
import { post } from "../services/service";
import { type } from "@testing-library/user-event/dist/type";
// import stonks from "../assets/stonks.jpg";
// import logo from "../assets/logo-black.png";
// import icon from "../assets/home.png";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Login from "./Login";

function Signup(props) {
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [imgUrl, setImgUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [firstnameStatus, setFirstnameStatus] = React.useState("");
  const [lastnameStatus, setLastnameStatus] = React.useState("");
  const [userStatus, setUserStatus] = React.useState("");
  const [emailStatus, setEmailStatus] = React.useState("");
  const [passStatus, setPassStatus] = React.useState("");
  const [confirmStatus, setConfirmStatus] = React.useState("");
  const [openLogin, setOpenLogin] = React.useState(false);

  const navigate = useNavigate();

  const create = async (e) => {
    e.preventDefault();
    try {
      if (
        !firstname ||
        !lastname ||
        !username ||
        !email ||
        password.length < 4 ||
        password !== confirmPassword
      ) {
        if (!firstname) {
          localStorage.clear();
          setFirstnameStatus("Please include first name");
        }
        if (!lastname) {
          localStorage.clear();
          setLastnameStatus("Please include last name");
        }
        if (!username) {
          localStorage.clear();
          setUserStatus("Please include username");
        }
        if (!email) {
          localStorage.clear();
          setEmailStatus("Please include email");
        }
        if (password.length < 4) {
          localStorage.clear();
          setPassStatus("Password must be 8-characters");
        }
        if (password !== confirmPassword) {
          localStorage.clear();
          setConfirmStatus("Passwords do not match");
        }
      } else {
        let response = await post("/users/signup", {
          firstname: firstname,
          lastname: lastname,
          username: username,
          email: email,
          password: password,
          // profilePic: imgUrl,
        });
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("email", response.data.email);
        // localStorage.setItem("profilePicture", response.data.profilePic);
        navigate("/dashboard/portfolio");
      }
    } catch (err) {
      console.log(err);
      if (err.response.data.includes("E11000" && "username")) {
        setUserStatus("Username already taken");
      } else if (err.response.data.includes("E11000" && "email")) {
        setEmailStatus("Email already taken");
      } else {
        console.log("Something went wrong");
      }
    }
  };

  // const handleFileUpload = async (e) => {
  //   setLoading(true);
  //   const uploadData = new FormData();
  //   uploadData.append("imageUrl", e.target.files[0]);
  //   let response = await post("/users/add-picture", uploadData);
  //   setImgUrl(response.data.path);
  //   setLoading(false);
  // };

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.open(false);
          // props.form("");
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const cleanFirstname = (value) => {
    setFirstnameStatus("");
    setFirstname(value);
  };

  const cleanLastname = (value) => {
    setLastnameStatus("");
    setLastname(value);
  };

  const cleanEmail = (value) => {
    setEmailStatus("");
    setEmail(value);
  };

  const cleanUser = (value) => {
    setUserStatus("");
    setUsername(value);
  };

  const cleanPass = (value) => {
    setPassStatus("");
    setPassword(value);
  };

  const cleanConfirm = (value) => {
    setConfirmStatus("");
    setConfirmPassword(value);
  };

  return (
    <div className="signup" ref={wrapperRef}>
      <h4>Create your Account</h4>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: "4.55px", width: "327px" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={create}
      >
        <span>
          <TextField
            // required
            error={firstnameStatus ? true : false}
            id="first-name"
            type="string"
            label="First Name"
            variant="standard"
            style={{
              width: "148.95px",
              marginRight: "10px",
            }}
            helperText={firstnameStatus ? firstnameStatus : " "}
            value={firstname}
            onChange={(e) => cleanFirstname(e.target.value)}
          />
          <TextField
            // required
            error={lastnameStatus ? true : false}
            id="last-name"
            type="string"
            label="Last Name"
            variant="standard"
            style={{
              width: "148.95px",
              marginLeft: "10px",
            }}
            helperText={lastnameStatus ? lastnameStatus : " "}
            value={lastname}
            onChange={(e) => cleanLastname(e.target.value)}
          />
        </span>
        <TextField
          // required
          error={emailStatus ? true : false}
          id="email"
          type="email"
          label="Email"
          variant="standard"
          helperText={emailStatus ? emailStatus : " "}
          value={email}
          onChange={(e) => cleanEmail(e.target.value)}
        />
        <TextField
          // required
          error={userStatus ? true : false}
          id="username"
          type="string"
          label="Username"
          variant="standard"
          helperText={userStatus ? userStatus : " "}
          value={username}
          onChange={(e) => cleanUser(e.target.value)}
        />
        <TextField
          // required
          error={passStatus ? true : false}
          id="password"
          type="password"
          label="Password"
          variant="standard"
          helperText="Must be 8-characters"
          value={password}
          onChange={(e) => cleanPass(e.target.value)}
        />
        <TextField
          // required
          error={confirmStatus ? true : false}
          id="confirm-password"
          type="password"
          label="Verify Password"
          variant="standard"
          helperText={confirmStatus ? confirmStatus : " "}
          value={confirmPassword}
          onChange={(e) => cleanConfirm(e.target.value)}
        />
        <div className="signup-bottom">
          <p>
            Already have an account?
            <span
              onClick={() => {
                props.form("login");
                // props.open(true);
              }}
            >
              Log in
            </span>
          </p>

          <button type="submit" className="signup-button">
            Submit
          </button>
        </div>
      </Box>
      {/* <p style={{ color: "red" }}>{status}</p> */}
    </div>
  );
}

export default Signup;
